export default {
	cinema: {
		address: 'ул. Олимпийская, д. 6, корп. 1',
		place: 'ТРК «Галерея 9-18»',
		phoneNumbers: ['+7 (499) 400-16-00'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinograd_vidnoe',
		twitter: null,
		odnoklassniki: null,
		youtube: 'https://www.youtube.com/channel/UCCiGtF7BR74-jGLuzZ8gJOg',
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CGgAeC0S',
		selectButton: {
			options: [{
				value: 'https://vidnoe.cinema-grad.com',
				title: 'Видное',
			},
			{
				value: 'https://polyus.cinema-grad.com',
				title: 'ТРК «Южный Полюс»',
			},
			{
				value: 'https://nahodka.cinema-grad.com',
				title: 'ТРК «Порт Находка»',
			}],
			selectedOptionValue: 'https://vidnoe.cinema-grad.com',
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
